import {
  CompanyCode,
  SORTERA_GOTEBORG,
  SORTERA_MALMO,
  SORTERA_OREBRO,
  SORTERA_STOCKHOLM,
} from '../data/company.constants'

export const STOCKHOLM = 'stockholm' as const
export const GOTEBORG = 'goteborg' as const
export const MALMO = 'malmo' as const
export const OREBRO = 'orebro' as const

export const REGIONS = [STOCKHOLM, GOTEBORG, MALMO, OREBRO]

export type RegionCode = (typeof REGIONS)[number]

export interface Position {
  lat: number
  lng: number
}

/**
 * Maps a region to its corresponding company for sorting.
 */
export function regionToCompany(region: RegionCode) {
  switch (region) {
    case GOTEBORG:
      return SORTERA_GOTEBORG

    case MALMO:
      return SORTERA_MALMO

    case OREBRO:
      return SORTERA_OREBRO

    case STOCKHOLM:
    default:
      return SORTERA_STOCKHOLM
  }
}

/**
 * Maps a company to its corresponding region.
 */
export function companyToRegion(company: CompanyCode): RegionCode {
  switch (company) {
    case SORTERA_GOTEBORG:
      return GOTEBORG

    case SORTERA_MALMO:
      return MALMO

    case SORTERA_OREBRO:
      return OREBRO

    case SORTERA_STOCKHOLM:
    default:
      return STOCKHOLM
  }
}

/**
 * Returns the center position of a given region.
 */
export function regionCenterPosition(region: RegionCode): Position {
  switch (region) {
    case GOTEBORG:
      return {
        lat: 57.70887,
        lng: 11.97456,
      }

    case MALMO:
      return {
        lat: 55.60587,
        lng: 13.00073,
      }

    case OREBRO:
      return {
        lat: 59.27516,
        lng: 15.21271,
      }

    case STOCKHOLM:
    default:
      return {
        lat: 59.334591,
        lng: 18.06324,
      }
  }
}

export const regions = {
  data() {
    return {
      STOCKHOLM,
      GOTEBORG,
      MALMO,
      OREBRO,
    }
  },
}
