import { ref } from 'vue'

import { Authentication, User } from '@sortera/firebase-auth'

import i18n from '@/i18n'
import { firebaseApp } from '@/plugins/firebase-db.js'
import { track, trackError } from '@/utils/analytics/track'
import { resetFailedRequestsCount } from '@/utils/auth-state'

const auth = new Authentication(
  {
    clientId: import.meta.env.VITE_AUTH_CLIENT_ID,
    redirectUri: window.location.origin + '/autentisering/logga-in',
    authority: `https://login.microsoftonline.com/${import.meta.env.VITE_AUTH_TENANT_ID}`,
    scopes: [import.meta.env.VITE_AUTH_GATEWAY_SCOPE],
    tenantId: import.meta.env.VITE_AUTH_TENANT_ID,
    onError: err => {
      console.error(err)
      trackError(err)
    },
  },
  firebaseApp
)

const account = ref<User | null>(null)

async function initializeAuth(options?: { caller: string }) {
  if (account.value != null) {
    // eslint-disable-next-line no-console
    console.log('Auth already initialized for', options?.caller, account.value.email)
    return
  }
  trackInitializedAuth()
  // eslint-disable-next-line no-console
  console.log('Initializing auth for', options?.caller)

  try {
    await auth.initialize()
  } catch (err) {
    alert(i18n.global.t('auth.failed-init'))
  }
  account.value = await auth.getUser()
}

function handleMsRedirect(): Promise<void> {
  return auth.handleMsRedirect()
}

async function login(): Promise<void> {
  trackLoggedIn()
  // eslint-disable-next-line no-console
  console.log('Logging in')
  await auth.login()
}

async function logout(): Promise<void> {
  trackLoggedOut()
  // eslint-disable-next-line no-console
  console.log('Logging out')
  account.value = null
  // failed request count is reset upon successful axios request, but not all pages make requests to the gateway
  resetFailedRequestsCount()
  await auth.logout()
}

async function getAccessToken(): Promise<string> {
  trackRequestedToken()
  // eslint-disable-next-line no-console
  console.log('Getting access token')
  return await auth.getAccessToken()
}

// Analytics

function trackInitializedAuth(): void {
  const EVENT = 'Initialized Auth' as const
  track(EVENT)
}

function trackLoggedIn(): void {
  const EVENT = 'Logged In' as const
  track(EVENT)
}

function trackLoggedOut(): void {
  const EVENT = 'Logged Out' as const
  track(EVENT)
}

function trackRequestedToken(): void {
  const EVENT = 'Requested Access Token' as const
  track(EVENT)
}

export function useAuth() {
  return {
    account,
    initializeAuth,
    login,
    logout,
    getAccessToken,
    handleMsRedirect,
  }
}
